.react-datepicker-wrapper{
    height: 7vw;
    width: 96%;
    margin-left: 1vw;
    margin-right: 1vw;
}
.react-datepicker__input-container input {
    height: 7vw;
    font-size: 3vw;
    width: 100%;
}
.react-datepicker__input-container{
    width: 100%;
}