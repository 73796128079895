body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
}

p{
    margin-bottom: 0 !important;
}

.homepage-video-player-container{
    width: 70.029vw;
    margin-top: 20.322vw;
    height: 58.333vw;
}

.homepage-btns-size{
    min-width: 27vw;
    /*width: 27vw;*/
    height: auto;
    font-size: 4vw !important;
}

.language-btn-container {
    width: 70%;
}

.header{
    height: 17.982vw;
    width: 100%;
}

.navbar-custom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 17.982vw;
    padding: 0 10px;
    background-color: #f8f9fa;
}

.navbar-custom .btn-link {
    background: none;
    border: none;
    font-size: 20px;
    color: #000;
}

.navbar-custom .bi {
    font-size: 25px;
}

.free-trial-imput-container{
    width: 28vw;
    max-width: 28vw;
    min-width: 28vw;
}

.free-trial-input{
    background-color: #D9D9D9;
    width: 26vw;
    height: 7.4vw;
    border-radius: 1vw;
    border: none;
}

.membership-info-block{
    background-color: #f1f1f1;
    border-radius: 2vw;
}

.membership-info-btns-bottom{
    font-size: 3.2vw !important;

}

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5); /* Dark overlay */
    backdrop-filter: blur(5px); /* Blur effect */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.popup-content {
    width: 90%;
    max-width: 600px;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    text-align: center;
}

.popup-content h2 {
    margin: 0 0 10px;
    font-size: 24px;
    color: #333;
}

.popup-content p {
    font-size: 18px;
    color: #666;
    margin: 10px 0 20px;
}

.popup-content button {
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.popup-content button:hover {
    background-color: #0056b3;
}

.internet-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.internet-popup-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.internet-popup-content h2 {
    margin-bottom: 10px;
    font-size: 24px;
    color: #333;
}

.internet-popup-content p {
    font-size: 18px;
    color: #555;
}



